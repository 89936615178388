import { Box, Flex, Text } from "@biblioteksentralen/react";
import { ResolvedPaletteColor, ResolvedRecommendation, ResolvedRecommendationSummary } from "@libry-content/common";
import { getPath } from "@libry-content/common";
import { useTranslation } from "../../../../utils/hooks/useTranslation";
import CoverImageWithPlaceholder from "../../../lists/CoverImageWithPlaceholder";
import { MediaTypesAndTargetAudiences } from "../../../lists/MediaTypesAndTargetAudiences";
import { Teaser } from "../../../recommendation/Teaser";
import { useRecommendationPalette } from "../../../recommendation/utils";
import { SingleItemBanner } from "../SingleItemBanner";
import { getContainerQuery } from "./utils";

export const FeaturedRecommendationBanner = ({
  recommendation,
  bannerColor,
}: {
  recommendation: ResolvedRecommendationSummary | ResolvedRecommendation;
  bannerColor?: ResolvedPaletteColor;
}) => {
  const { t } = useTranslation();
  const [title] = recommendation.publication?.title?.split(":") ?? [];
  const palette = useRecommendationPalette(recommendation);

  const image = recommendation.publication && (
    <Flex
      justifyContent="center"
      placeItems="center"
      height="100%"
      sx={getContainerQuery({ base: { padding: "2rem" }, sm: { padding: "0" } })}
    >
      <Box sx={getContainerQuery({ base: { display: "block" }, sm: { display: "none" } })}>
        <CoverImageWithPlaceholder publication={recommendation.publication} imageWidth="7rem" minWidth="3rem" />
      </Box>
      <Box sx={getContainerQuery({ base: { display: "none" }, sm: { display: "block" } })}>
        <CoverImageWithPlaceholder publication={recommendation.publication} imageWidth="10rem" minWidth="3rem" />
      </Box>
    </Flex>
  );

  return (
    <SingleItemBanner
      aria-description={t("anbefaling")}
      as="aside"
      seeMore={{ href: getPath(recommendation), label: t("Se anbefalingen") }}
      title={title}
      tagline={
        <MediaTypesAndTargetAudiences
          mediaTypes={recommendation.publication?.mediaTypes}
          targetAudiences={recommendation.targetAudiences}
        />
      }
      customImageComponent={image}
      editDocumentProps={recommendation}
      bannerColor={bannerColor}
      flexProps={{ ...palette.mainPalette }}
      extraInfo={
        <>
          <Text fontSize="larger">{recommendation.publication?.author}</Text>
          <Teaser
            gridColumn="2/3"
            gridRow="2/3"
            alignSelf="center"
            padding=".5rem .75rem .5rem .25rem"
            fontSize="xs"
            fontWeight={600}
            {...palette.teaserTextProps}
            noOfLines={4}
            recommendation={recommendation}
            quoteMarkProps={{ ...palette.quoteMarkProps, marginRight: "-.25em" }}
            placeSelf="start"
          />
        </>
      }
    />
  );
};
