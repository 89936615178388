import { Badge, Box, Flex } from "@biblioteksentralen/react";
import { getPath } from "@libry-content/common";
import { useTranslation } from "../../../../utils/hooks/useTranslation";
import { useSitePalette } from "../../../../utils/useSitePalette";
import { ThreeCoversSideBySide } from "../../../lists/BookListPreviewLarge";
import { BookListCoversFan } from "../../../lists/CoversFan";
import { MediaTypesAndTargetAudiences } from "../../../lists/MediaTypesAndTargetAudiences";
import SingleItemBanner from "../SingleItemBanner";
import { getContainerQuery } from "./utils";
import { ResolvedListSummary } from "@libry-content/common";

type Props = {
  list: ResolvedListSummary;
  tagline?: string;
};

export const FeaturedListBanner = ({ list, tagline }: Props) => {
  const { t, ts } = useTranslation();
  const paletteColor = useSitePalette();

  const imagesComponent = (
    <Flex role="group" placeItems="center" height="100%">
      <Box
        sx={getContainerQuery({ base: { display: "none" }, lg: { display: "block", padding: "2rem 0 2rem 2rem" } })}
        flexGrow={1}
      >
        <ThreeCoversSideBySide list={list} />
      </Box>
      <Flex
        sx={getContainerQuery({ base: { display: "flex", paddingTop: "2rem 0" }, lg: { display: "none" } })}
        flexGrow={1}
        justifyContent="center"
      >
        <BookListCoversFan publications={list.publications} maxWidth="22rem" />
      </Flex>
    </Flex>
  );

  return (
    <SingleItemBanner
      aria-description={t("anbefalinger")}
      as="aside"
      seeMore={{ href: getPath(list), label: t("Se liste") }}
      title={ts(list.title)}
      teaser={ts(list.teaser)}
      tagline={
        list.targetAudiences?.length ? (
          <MediaTypesAndTargetAudiences mediaTypes={list.mediaTypes} targetAudiences={list.targetAudiences} />
        ) : (
          <Badge>{tagline}</Badge>
        )
      }
      customImageComponent={imagesComponent}
      editDocumentProps={list}
      bannerColor={paletteColor.colors.card}
      promotion={list.promotion}
    />
  );
};
