import {
  Box,
  colors,
  Flex,
  Grid,
  Heading,
  HideWithCSS,
  LinkBox,
  Stack,
  StackDivider,
  StackProps,
  VisuallyHidden,
} from "@biblioteksentralen/react";
import { Library } from "@libry-content/types";
import { ReactNode, useId } from "react";
import { getPath } from "@libry-content/common";
import { useTranslation } from "../../../../utils/hooks/useTranslation";
import useRerenderInterval from "../../../../utils/useRerenderInterval";
import { DefaultContainer } from "../../../ContentContainer";
import { Edit } from "../../../editInSanity/EditInSanity";
import InternalLinkOverlay from "../../../InternalLinkOverlay";
import { useCommonData } from "../../../layout/CommonDataProvider";
import { useSortedLibraries } from "../../../library/utils";
import { DivergentOpeninghoursIcon } from "../../../openingHours/DivergentOpeninghoursIcon";
import { OpeningHoursForDate, OpeningHoursHelper } from "../../../openingHours/openingHoursHelper/openingHoursHelper";
import { OpeningHoursStatus } from "../../../openingHours/OpeningHoursStatus";
import { TimespansOrClosed } from "../../../openingHours/TimeSpan";
import { useSelfServiceLabel } from "../../../openingHours/utils";
import SeMerLenke from "../../SeMerLenke";

type Props = {
  libraries: Library[];
};

export const OpeningHoursBanner = (props: Props) => {
  useRerenderInterval(); // Holder åpningstidbanneret oppdatert dersom man lar fanen stå åpen til neste dag

  if (!props.libraries.length) return null;

  if (props.libraries.length === 1) return <SingleLibraryBanner library={props.libraries[0]!} />;

  return <MultipleLibrariesBanner {...props} />;
};

export const MultipleLibrariesBanner = (props: Props) => {
  const sortedLibraries = useSortedLibraries(props.libraries);

  const headingId = useId();
  const { site } = useCommonData();
  const { t, ts } = useTranslation();

  return (
    // data-nosnippet for at dette ikke skal indekseres av google etc
    <DefaultContainer data-nosnippet>
      <Stack as="section" aria-labelledby={headingId} spacing={{ base: "1rem", md: "2rem" }}>
        <Heading as="h2" id={headingId}>
          {t("Åpningstider i dag")}
        </Heading>
        <Stack fontSize="lg">
          <Stack
            as="ol"
            spacing={{ base: ".5rem", md: 0 }}
            divider={<StackDivider borderColor={{ base: "transparent", md: colors.grey15 }} />}
          >
            <TopRow />
            {sortedLibraries.map((library) => (
              <OpeningHoursRow
                key={library._id}
                as={LinkBox}
                label={
                  <InternalLinkOverlay href={getPath(library)} fontWeight={600}>
                    {ts(library.name)}
                  </InternalLinkOverlay>
                }
                status={<OpeningHoursStatus library={library} />}
                openingHoursForDate={new OpeningHoursHelper(site, library).todaysOpeningHours}
                childrenBellow={<Edit doc={library} />}
                _hover={{ background: "rgba(0,0,0,.05)" }}
              />
            ))}
          </Stack>
          <SeMerLenke href="/bibliotek" alignSelf="flex-end" marginTop="2rem !important">
            {t("Våre bibliotek")}
          </SeMerLenke>
        </Stack>
      </Stack>
    </DefaultContainer>
  );
};

export const SingleLibraryBanner = (props: { library: Library }) => {
  const headingId = useId();
  const { site } = useCommonData();
  const { t } = useTranslation();
  const { todaysOpeningHours, tomorrowsOpeningHours } = new OpeningHoursHelper(site, props.library);

  return (
    // data-nosnippet for at dette ikke skal indekseres av google etc
    <DefaultContainer data-nosnippet>
      <Box as="section" aria-labelledby={headingId}>
        <Heading as="h2" id={headingId} paddingY="2rem">
          {t("Åpningstider")}
        </Heading>
        <Stack fontSize="lg">
          <OpeningHoursStatus library={props.library} />
          <Stack
            as="ol"
            spacing={0}
            divider={<StackDivider borderColor={{ base: "transparent", md: colors.grey15 }} />}
          >
            <TopRow />
            <OpeningHoursRow label={t("I dag")} openingHoursForDate={todaysOpeningHours} />
            <OpeningHoursRow label={t("I morgen")} openingHoursForDate={tomorrowsOpeningHours} />
          </Stack>
          <SeMerLenke href={getPath(props.library)} alignSelf="flex-end" marginTop="2rem !important">
            {t("Se flere åpningstider")}
          </SeMerLenke>
        </Stack>
      </Box>
    </DefaultContainer>
  );
};

const TopRow = () => {
  const { site } = useCommonData();
  const { t } = useTranslation();
  const selfServiceLabel = useSelfServiceLabel();

  if (!site?.hasSelfService) return null;

  return (
    // Aria-hidden. Bruker skjulte titler ved timespans istedenfor
    <Flex display={{ base: "none", md: "flex" }} justifyContent="flex-end" padding=".75rem .25rem" aria-hidden>
      <Grid width="max(30%, 16rem)" templateColumns="1fr 1fr" gap="2rem" fontWeight={600}>
        <Box>{t("Betjent")}</Box>
        <Box _firstLetter={{ textTransform: "uppercase" }}>{selfServiceLabel}</Box>
      </Grid>
    </Flex>
  );
};

const OpeningHoursRow = ({
  label,
  status,
  openingHoursForDate,
  childrenBellow,
  ...chakraProps
}: {
  label?: ReactNode;
  status?: ReactNode;
  openingHoursForDate: OpeningHoursForDate;
  childrenBellow?: ReactNode;
} & StackProps) => {
  const siteHasSelfService = useCommonData().site?.hasSelfService;
  const selfServiceLabel = useSelfServiceLabel();
  const { t } = useTranslation();
  const headingId = useId();

  return (
    <Box as="li" listStyleType="none" aria-labelledby={headingId}>
      <Stack padding=".75rem .25rem" {...chakraProps}>
        <Flex gap={{ base: ".75rem", md: "1rem" }} flexDirection={{ base: "column", md: "row" }}>
          <Grid flex="1" templateColumns={{ md: "min(14rem, 50%) 1fr" }} gap=".25em 1em">
            <Box as="h3" fontSize={{ base: "2xl", md: "inherit" }} fontWeight={600} id={headingId}>
              {label}
            </Box>
            <Box minW="7rem">{status}</Box>
          </Grid>
          <Grid
            gap="2rem"
            width={siteHasSelfService ? "max(30%, 16rem)" : "max(15%, 7.5rem)"}
            templateColumns={siteHasSelfService ? "1fr 1fr" : "1fr"}
          >
            <Hours label={t("Betjent")} hideLabel={!siteHasSelfService} hours={openingHoursForDate.normalHours} />
            {siteHasSelfService && <Hours label={selfServiceLabel} hours={openingHoursForDate.selfService} />}
          </Grid>
        </Flex>
      </Stack>
      {childrenBellow}
    </Box>
  );
};

const Hours = (props: {
  label: string;
  hideLabel?: boolean;
  hours?: OpeningHoursForDate["normalHours"] | OpeningHoursForDate["selfService"];
}) => {
  const isSpecial = props.hours?.isSpecial;
  return (
    <Box fontWeight={{ md: 600 }} color={isSpecial ? colors.statusYellow : undefined}>
      {!props.hideLabel && (
        <>
          <HideWithCSS fontSize="sm" aria-hidden above="md" _firstLetter={{ textTransform: "uppercase" }}>
            {props.label}:
          </HideWithCSS>
          <VisuallyHidden>{props.label}</VisuallyHidden>
        </>
      )}
      <Flex alignItems="center" gap=".3em">
        {isSpecial && <DivergentOpeninghoursIcon />}
        <TimespansOrClosed spans={props.hours?.spans} />
      </Flex>
    </Box>
  );
};
