import { Box, BoxProps } from "@biblioteksentralen/react";
import {
  getEventHeadings,
  isRepeatedEventOccurence,
  ResolvedEventSummary,
  ResolvedPaletteColor,
} from "@libry-content/common";
import { getPath } from "@libry-content/common";
import { useTranslation } from "../../../../utils/hooks/useTranslation";
import EventImageDateBadge from "../../../arrangement/EventImageDateBadge";
import { EventStatusBadge } from "../../../arrangement/EventStatus";
import SingleItemBanner, { SingleItemBannerImage } from "../SingleItemBanner";
import { EventTimeAndPlace } from "../../../arrangement/EventsList";

type Props = { event: ResolvedEventSummary; bannerColor: ResolvedPaletteColor } & BoxProps;

export function FeaturedEventBanner({ event, bannerColor, ...boxProps }: Props) {
  const { t, ts } = useTranslation();

  const image = (
    <Box height="100%">
      <Box position="relative" height="100%">
        <SingleItemBannerImage borderRadius="0" image={event.image} />
        <EventImageDateBadge date={event.eventSchedule?.startsAt} />
        <EventStatusBadge event={event} fontSize={{ base: "1xl", sm: "2xl" }} />
      </Box>
    </Box>
  );

  const { heading, subheading } = getEventHeadings(event);

  return (
    <SingleItemBanner
      aria-description={t("arrangement")}
      as="aside"
      bannerColor={bannerColor}
      imageSide="right"
      customImageComponent={image}
      seeMore={{ href: getPath(event), label: t("Se arrangementet") }}
      editDocumentProps={{
        _id: isRepeatedEventOccurence(event) ? event.series.id : event._id,
        _type: "event",
      }}
      promotion={event.promotion}
      title={ts(heading)}
      tagline={ts(subheading)}
      teaser={ts(event.teaser)}
      extraInfo={<EventTimeAndPlace event={event} />}
      {...boxProps}
    />
  );
}
