import { Box, BoxProps, Heading, HStack, Stack, Text, VisuallyHidden } from "@biblioteksentralen/react";
import { useId } from "react";
import { FrontPageData } from "../../../app/[locale]/sites/[siteDomain]/page";
import { getPath } from "@libry-content/common";
import { useTranslation } from "../../../utils/hooks/useTranslation";
import { useSitePalette } from "../../../utils/useSitePalette";
import { DefaultContainer } from "../../ContentContainer";
import { SanityImageFillAvailableSpace } from "../../images/SanityImageFillAvailableSpace";
import { useSortedLibraries } from "../../library/utils";
import SeMerLenke from "../SeMerLenke";
import { ResolvedPaletteColor } from "@libry-content/common";

type Props = Pick<FrontPageData, "libraries">;

const useLibrariesBannerColors = () => {
  const palette = useSitePalette();

  return [palette.colors.darkaccent4, palette.colors.darkaccent2, palette.colors.darkaccent1];
};

export const LibrariesBanner = (props: Props) => {
  const sortedLibraries = useSortedLibraries(props.libraries);
  const flereFilialer = sortedLibraries.length > 1;
  const headerId = useId();
  const { t } = useTranslation();
  const backgroundColors = useLibrariesBannerColors();

  if (!sortedLibraries.length) return null;

  return (
    <Box as="section" aria-labelledby={headerId}>
      <VisuallyHidden>
        <h2 id={headerId}>{flereFilialer ? t("Bibliotekene våre") : t("Biblioteket vårt")}</h2>
      </VisuallyHidden>
      {flereFilialer ? (
        <ul>
          {sortedLibraries.map((library, i) => (
            <Library
              as="li"
              key={library._id}
              library={library}
              imageToTheLeft={i % 2 === 1}
              paletteColor={backgroundColors[i % backgroundColors.length]}
            />
          ))}
        </ul>
      ) : (
        <Library library={sortedLibraries[0]!} paletteColor={backgroundColors[0]} />
      )}
    </Box>
  );
};

const Library = ({
  library,
  imageToTheLeft,
  paletteColor,
  ...chakraProps
}: {
  library: Props["libraries"][number];
  imageToTheLeft?: boolean;
  paletteColor?: ResolvedPaletteColor;
} & BoxProps) => {
  const { ts, t } = useTranslation();
  const headingId = useId();

  return (
    <Box
      padding={{ base: "7rem 0 3rem", sm: "8rem 0 3rem", md: "4rem 0 3rem" }}
      aria-labelledby={headingId}
      {...chakraProps}
    >
      <DefaultContainer>
        <HStack
          {...paletteColor?.css}
          borderRadius="lg"
          flexDirection={imageToTheLeft ? "row-reverse" : "row"}
          display={{ base: "block", md: "flex" }}
          position="relative"
          {...{ [imageToTheLeft ? "marginLeft" : "marginRight"]: { md: "2rem" } }}
        >
          <Stack spacing="1.5rem" flex="1" padding="2rem">
            <Heading as="h3" size="lg" id={headingId}>
              {ts(library.name)}
            </Heading>
            <Text>{ts(library.teaser)}</Text>
            <SeMerLenke
              alignSelf="flex-end"
              whiteSpace="unset"
              height="auto"
              minH="2.5rem"
              paddingY=".5rem"
              textAlign="center"
              href={getPath(library)}
            >
              {t("Gå til {name}", { name: ts(library.name) })}
            </SeMerLenke>
          </Stack>
          <Box
            flex="1"
            position={{ base: "absolute", md: "relative" }}
            top={{ base: "-5.5rem", sm: "-8.5rem", md: "unset" }}
            right={{ base: ".5rem", md: "unset" }}
            alignSelf="stretch"
            width={{ base: "50%", md: "100%" }}
            height={{ base: "7rem", sm: "10rem", md: "unset" }}
            borderRadius="lg"
            border=".3rem solid"
            borderColor={paletteColor?.css.background}
            overflow="hidden"
            transform={{ md: imageToTheLeft ? "translate(-2rem, -2rem)" : "translate(2rem, -2rem)" }}
            {...{ [imageToTheLeft ? "marginRight" : "marginLeft"]: { md: "-3rem" } }}
          >
            <SanityImageFillAvailableSpace borderRadius="none" hideCaption image={library.image} resolution={1080} />
          </Box>
        </HStack>
      </DefaultContainer>
    </Box>
  );
};
