import { Grid, Heading, Stack } from "@biblioteksentralen/react";
import { useId } from "react";
import { getPath } from "@libry-content/common";
import { useTranslation } from "../../../utils/hooks/useTranslation";
import { useSitePalette } from "../../../utils/useSitePalette";
import { DefaultContainer } from "../../ContentContainer";
import { CreateNew } from "../../editInSanity/EditInSanity";
import { ResolvedServiceSummary } from "@libry-content/common/src/services/groq/resolveService";
import { useSortedServices } from "../../services/utils";
import SeMerLenke from "../SeMerLenke";

interface Props {
  services?: ResolvedServiceSummary[];
}

export const ServicesBanner = (props: Props) => {
  const headingId = useId();
  const { t, ts } = useTranslation();
  const sortedServices = useSortedServices(props.services);
  const palette = useSitePalette();

  if (!sortedServices.length) return null;

  return (
    <Stack spacing="1rem" as="section" aria-labelledby={headingId}>
      <DefaultContainer>
        <Heading as="h2" id={headingId}>
          {t("Tjenester og tilbud hos oss")}
        </Heading>
        <CreateNew marginTop=".5rem" type="service" />
      </DefaultContainer>
      <DefaultContainer withoutPaddingXBellow="md">
        <Grid
          gridTemplateColumns="repeat(auto-fill, minmax(14rem, 1fr))"
          gap={{ base: ".75rem 1.5rem", md: "1.5rem 2rem" }}
          justifyItems="start"
          padding={{ base: "2rem 1rem", md: "2.5rem 2rem" }}
          borderRadius={{ md: "lg" }}
          {...palette.colors.pop1.css}
        >
          {sortedServices.map((service) => (
            <SeMerLenke
              iconLeft
              paddingLeft=".25rem"
              key={service._id}
              href={getPath(service)}
              whiteSpace="normal"
              variant="tertiary"
              color="inherit"
            >
              {ts(service.title)}
            </SeMerLenke>
          ))}
        </Grid>
      </DefaultContainer>
      <DefaultContainer display="flex">
        <SeMerLenke href="/tjenester" marginLeft="auto">
          {t("Se alle tjenestene våre")}
        </SeMerLenke>
      </DefaultContainer>
    </Stack>
  );
};
