import { colors, Grid, Icon } from "@biblioteksentralen/react";
import { Info } from "react-feather";
import { getPath } from "@libry-content/common";
import { useTranslation } from "../../../../utils/hooks/useTranslation";
import { ResolvedStaticPageSummary } from "../../../staticPage/sanityQuery";
import SingleItemBanner from "../SingleItemBanner";
import { getContainerQuery } from "./utils";
import { useCommonData } from "../../../layout/CommonDataProvider";
import { ResolvedPaletteColor } from "@libry-content/common";

export function FeaturedStaticPageBanner(props: {
  staticPage: ResolvedStaticPageSummary;
  bannerColor: ResolvedPaletteColor;
}) {
  const { bannerColor, staticPage } = props;
  const { t, ts } = useTranslation();
  const footerMenuCollection = useCommonData().headerFooterData?.footerMenuCollection;

  const footerMenuHeader =
    footerMenuCollection?.[(staticPage.footerMenuKey ?? "") as NonNullable<ResolvedStaticPageSummary["footerMenuKey"]>]
      ?.menuHeader;

  const fallbackImage = (
    <Grid
      maxH="100%"
      height="100%"
      placeItems="center"
      background={`linear-gradient(45deg, ${colors.grey15}, ${colors.grey10})`}
      color={bannerColor.css.background}
      padding="5%"
    >
      <Icon
        as={Info}
        size="1em"
        sx={getContainerQuery({
          base: { fontSize: "8rem" },
          sm: { fontSize: "12rem" },
          md: { fontSize: "14rem" },
        })}
      />
    </Grid>
  );

  return (
    <SingleItemBanner
      as="aside"
      bannerColor={bannerColor}
      sanityImageSrc={staticPage.image}
      seeMore={{ href: getPath(staticPage), label: t("Se mer") }}
      editDocumentProps={staticPage}
      customImageComponent={!staticPage.image?.asset ? fallbackImage : undefined}
      tagline={ts(footerMenuHeader)}
      title={ts(staticPage.title)}
      teaser={ts(staticPage.excerpt)}
      promotion={staticPage.promotion}
    />
  );
}
